/* Color */
$main_color: #309CF1;
$accent_color: #F1AB30;
$error_color: #F23034;
$black: #333;
$dark_blue: #333b53;
$dark_gray: #999;
$gray: #CCC;
$light_gray: #EEE;
$white: #FFF;
$light_green: #A0FF00;
$main_color_not_hover: rgba(48, 156, 241, 0.8);
$white_not_hover: rgba(255, 255, 255, 0.8);
$box_shadow_color: rgba(34,36,38,.15);
$txt_shadow_color: rgba(0, 0, 0, 0.5);
/* Font */
$default_size: 0.875rem;
$small_size: 0.75rem;
$large_size: 1.125rem;
$default_line_height: 1.25rem;
$small_line_height: 1.0rem;
$large_line_height: 1.375rem;
$default_weight: 300;
$bold_weight: 600;
$default_letter_spacing: 0.1rem;
/* Space */
$padding: 5px;
$margin: 5px;
/* border */
$default_border_radius: .28571429rem;
/* mixin */
@define-mixin btn $btn-width, $btn-height, $btn-color, $btn-background {
  width: $(btn-width);
  height: $(btn-height);
  color: $(btn-color);
  background: $(btn-background);
  border-radius: $default_border_radius;
  cursor: pointer;
  &:hover {
    opacity: 0.5; }}
@define-mixin container $container-width, $container-height, $container-background: $white, $container-display: block {
  width: $(container-width);
  height: $(container-height);
  background: $(container-background);
  display: $(container-display); }
@define-mixin flexbox $flexbox-direction, $flexbox-justify-content, $flexbox-align-items, $flexbox-wrap: nowrap {
  display: flex !important;
  flex-direction: $(flexbox-direction);
  justify-content: $(flexbox-justify-content);
  align-items: $(flexbox-align-items);
  flex-wrap: $(flexbox-wrap); }
@define-mixin tsize $tsize-size, $tsize-weight, $tsize-line-height {
  font-size: $(tsize-size);
  font-weight: $(tsize-weight);
  line-height: $(tsize-line-height); }
@define-mixin txt $txt-size, $txt-weight, $txt-line-height, $txt-color: $black, $txt-background: $white, $txt-align: left {
  @mixin tsize $(txt-size), $(txt-weight), $(txt-line-height);
  color: $(txt-color);
  background: $(txt-background);
  text-align: $(txt-align); }
html {
  height: 100%; }
body{
  @mixin txt $default_size, $default_weight, $default_line_height;
  font-family: "ヒラギノ角ゴシック","Hiragino Kaku Gothic ProN","Oswald","メイリオ","Meiryo","sans-serif";
  margin: 0;
  min-height: 100vh; }
header{
  @mixin flexbox row, space-between, center;
  background-color: $black;
  height: 52px;
  padding: 0 $padding;
  margin: 0;
  font-size: 0; }
fieldset{
  padding: 2.5% 0;
  margin: 0 10px !important;
  border: none; }
button{
  @mixin btn auto, auto, $black, $white; }
a{
  text-decoration: none; }
.button a, .button a:visited{
  color: $white; }
select{
  appearance: none; }
::-webkit-scrollbar{
  display: none; }
header ::selection{
  background-color: unset;
  color: inherit; }
h5.ui.header{
  margin-top: 10px !important; }
.header__browser{
  @mixin flexbox row, flex-end, center;
  height: 100%; }
.header__right__padding{
  padding-right: calc(12*$padding); }
.header h1{
  display: inline-block;
  color: $black;
  height: 100%;
  margin: 0;
  padding: 0; }
.header h1 a{
  display: block;
  text-align: center;
  width: 153px;
  height: 52px;
  background: url('../img/logo.png') center center /153px 36px no-repeat !important;
  text-indent: -9999px;
  overflow: hidden; }
.header__menu__login{
  color: $main_color_not_hover !important; }
.header__menu__login:hover{
  color: $main_color !important; }
#content {
  position: relative;
  min-height: calc(100vh - 250px); }
a:visited{
  color: $black; }
.link, .link:hover, .link:visited{
  color: $main_color !important; }
a.link:hover{
  text-decoration: underline; }
footer{
  margin-top: 10em;
  position: relative;
  z-index: 120;
  width: 100%; }
.footer__copyright{
  padding-top: calc(10*$padding);
  margin: 0 auto calc(4*$margin);
  text-align: center;
  font-family: arial, sans-serif; }
.footer__copyright__name, .footer__copyright__name:visited{
  color: $black !important; }
.copyright__symbol{
  vertical-align: bottom; }
.footer__link{
  width: 100%;
  @mixin flexbox row, center, center; }
.footer__link__item{
  display: inline-block;
  text-align: center;
  cursor: pointer;
  color: $black;
  margin: calc(2*$margin); }
.header__menu {
  @mixin container auto, 100%, $black, inline-block;
  list-style: none;
  margin: 0;
  padding: 0; }
.header__menu li{
  position: relative;
  display: block;
  height: 100%; }
.header__menu li a, .header__menu li span{
  @mixin txt $large_size, $default_weight, 52px, $white_not_hover, $black, center;
  display: inline-block;
  cursor: pointer;
  letter-spacing: $default_letter_spacing;
  padding: 0 calc(3*$padding); }
.header__menu li a:hover, .header__menu li span:hover{
  color: $white; }
.header__menu li span.selected{
  background-color: $dark_gray;
  color: $accent_color !important; }
.header__menu li span ul{
  display: block;
  position: absolute;
  list-style: none;
  z-index: 1001;
  margin: 0 0 0 calc(-18*$margin);
  padding: 0; }
.header__menu__submenu__logined{
  @mixin flexbox column, flex-end, flex-end;;
  position: absolute !important;
  top: 0;
  right: 0;
  height: auto !important;
  z-index: 999;
  background: transparent !important; }
.header__menu__submenu__logined__list li{
  @mixin container 100%, 52px, transparent; }
.header__menu__submenu__logined__list li a, .header__menu__submenu__logined__list li span{
  @mixin container 100%, 100%, $black;
  text-align: left;
  padding: $padding calc(2*$padding);
  line-height: 40px !important; }
.header__menu__submenu__logined__list li a:hover, .header__menu__submenu__logined__list li span:hover{
  background-color: $black;
  color: $white; }
#header__menu__submenu__logined__button{
  cursor: pointer; }
.header__menu__submenu__logined__button.is_login{
  @mixin container 52px, 52px, $black;
  border: 5px solid $black;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
.header__menu__submenu__logouted__list{
  margin-left: 0px !important; }
.main{
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto; }
#menu_container {
  background-color: $white; }
.terms__link__container{
  color: $black;
  padding: $padding;
  text-align: center; }
.terms__link__container a, .terms__link__container a:visited{
  color: $main_color; }
.live__section-contents{
  @mixin flexbox row, space-between, center, wrap; }
.live__list{
  @mixin container calc(50% - 10px), calc(28*$padding), transparent, grid;
  grid-template-rows: 1fr 2fr 1fr;
  grid-template-columns: 213px 1fr;
  column-gap: 2%;
  margin: calc(4*$margin) 1px 1px;
  padding: calc(2*$padding);
  border-radius: 4px;
  background-color: $white;
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0,0,0,.4); }
.live__list__thumb{
  grid-row: 1 / 4;
  grid-column: 1;
  width: 100%;
  margin: 0; }
.live__list__thumb__img{
  height: calc(24*$padding); }
.live__list__status__container{
  @mixin container 100%, auto;
  margin-top: calc(-6*$margin);
  text-align: center; }
.live__list__status__container span{
  border-radius: $default_border_radius;
  color: $white;
  padding: $padding; }
.live__list__info__title__container{
  @mixin container 100%, auto;
  grid-row: 1;
  grid-column: 2;
  padding-top: $padding; }
.live__list__info__title{
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap:break-word; }
.live__list__info__description__container{
  @mixin container 100%, auto;
  grid-row: 2;
  grid-column: 2;
  margin-right: calc(2*$margin);
  padding: $padding; }
.live__list__info__description{
  overflow: visible;
  white-space: normal;
  word-wrap:break-word; }
.live__list__info__user__container{
  @mixin container 100%, auto;
  @mixin flexbox row, space-between, center;
  grid-row: 3;
  grid-column: 2;
  overflow: visible;
  white-space: normal;
  word-wrap:break-word;
  padding: $padding 0; }
.live__list__info__user__thumb{
  @mixin container 30px, 30px; }
.live__list__info__user__thumb img{
  width: 100%; }
.live__list__info__user__name{
  width: calc(95% - 2.5vw);
  margin-left: 5%;
  padding-top: $padding;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap:break-word; }
.live__before__text, .live__finish__text, .live__open__text{
  background: $dark_gray;
  border: 1px solid $white;
  border-radius: $default_border_radius; }
.live__open__text{
  background: $main_color; }
.live__empty__text{
  margin: calc(6*$margin) 0; }
#vrlive-form {
  margin: calc(4*$margin) 0 calc(6*$margin); }
.form-search-container{
  @mixin container 35%, auto;
  float: right; }
.form-order-container{
  @mixin container 60%, auto;
  float: left; }
#fanclub__plan__section-contents .fanclub__plan__list{
  box-shadow: 0 1px 2px 0 $box_shadow_color !important;
  margin: 1rem 0 !important;
  padding: 0 1em 0 0 !important;
  border-radius: .28571429rem !important;
  border: 1px solid $box_shadow_color !important; }
div.fanclub__plan__list__button__join{
  @mixin btn auto, auto, $white !important, $main_color !important;
  cursor: pointer;
  border: solid 1px $main_color !important; }
.fanclub__plan__list__thumb__img{
  background: $gray !important; }
.fanclub__plan__list__content{
  @mixin flexbox column, space-evenly, initial; }
.fanclub__plan__message__text, .fanclub__plan__message__small__text{
  width: 80% !important; }
.fanclub__plan__message__small__text{
  @mixin txt $default_size !important, $default_weight, $default_line_height !important; }
.prize__section-contents{
  margin-top: 20px; }
.prize__img{
  width: 100%; }
.prize__text{
  display: block;
  text-align: center; }
.section-header{
  display: table;
  background-color: $white;
  overflow: hidden;
  width: 100%; }
.section-header__texts{
  display: table-cell;
  vertical-align: middle; }
.section-header__button{
  display: table-cell;
  vertical-align: middle; }
.section-notes{
  @mixin container 100%, auto, $white;
  padding: calc(2*$padding); }
.dialog-payment-bg{
  @mixin container 100vw, 100vh, $black;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5; }
.dialog-payment{
  @mixin container 310px, 450px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center; }
.dialog-payment__wrap_btn_commit{
  padding: calc(2*$padding);
  text-align: center; }
.dialog-payment__btn_commit{
  @mixin txt 37px, $default_weight, $default_line_height;
  cursor: pointer; }
.img__file{
  height: 200px;
  float: left;
  margin: 0;
  background: url('../img/no_image.jpg') no-repeat;
  background-size: contain; }
.label_wrapper{
  width: 250px;
  min-height: 24px;
  padding: 0;
  float: left; }
.label_wrapper label{
  font-weight: $default_weight;
  margin-bottom: 0;
  color: $black; }
.label_wrapper label.error{
  color: $accent_color; }
.input_container{
  @mixin container 100%, auto;
  padding: 0;
  float: left; }
.input_container input{
  @mixin container 100%, 30px;
  border: solid 1px $gray;
  padding: $padding;
  border-radius: $default_border_radius; }
.input_container textarea{
  @mixin container 100%, 80px;
  border: solid 1px $gray;
  border-radius: $default_border_radius; }
.input_container input.error, .input_container textarea.error{
  border: 1px solid $error_color !important; }
.input_error_container{
  @mixin container 100%, auto;
  color: $error_color;
  padding: 10px; }
.img_input input{
  border: none; }
.form-img-description{
  @mixin container 100%, auto, transparent;
  position: absolute;
  bottom: 0;
  color: $dark_gray;
  white-space: nowrap;
  text-overflow: ellipsis; }
.form-common-description{
  color: $dark_gray;
  margin: calc(2*$margin) 0; }
.form-cancel-button{
  @mixin btn auto, auto, $white, $main_color;
  margin: calc(4*$margin) 0 0 0;
  padding: $padding calc(4*$padding);
  float: right;
  text-align: center !important;
  border: solid 1px $main_color; }
.form-submit-button{
  @mixin btn auto, auto, $white, $main_color;
  margin: calc(4*$margin) 0 0 calc(4*$margin);
  padding: $padding calc(4*$padding);
  float: right;
  text-align: right !important;
  border: solid 1px $main_color; }
@keyframes loading{
  100% {
    transform: rotate(360deg);
  } }
#purchase__form{
  width: 600px;
  margin: calc(6*$margin) auto; }
.purchase__form__point-container{
  @mixin container auto, auto;
  padding: calc(2*$padding);
  border-bottom: 1px solid $dark_gray;
  text-align: left !important; }
.purchase__form__note{
  color: $accent_color;
  padding: $padding; }
.common__list__item{
  padding: calc(4*$padding);
  .label_wrapper{
    text-align: left; }
  .purchase__form__label{
    @mixin container 150px, auto, url('../img/coins.png') left center /28px no-repeat;
    text-align: right !important;
    padding: $padding 0; }
  .label_wrapper label{
    font-weight: $bold_weight; }
  span{
    margin: 0;
    padding: $padding;
    width: 300px;
    text-align: left;
    border: none; }}
#tradelaw .common__list__item{
  padding: 30px 10px 5px; }
.purchase__form__button{
  @mixin btn 100px, auto, $white, $main_color !important;
  margin: 0;
  padding: $padding;
  text-align: center !important;
  border: none; }
.btn-buy {
  display: block; }
.btn-buy:visited {
  color: $white !important; }
.form__overlay{
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background:rgba(0,0,0,0.3); }
.form__complete, .fanclub__plan__leave-form{
  margin: calc(20*$margin) auto;
  min-width: 320px;
  width: 60%;
  background: $white;
  border-radius: $default_border_radius;
  padding: calc(6*$padding) calc(4*$padding); }
.fanclub__plan__leave-form{
  width: 80%; }
.form__complete span, .fanclub__plan__leave-form span{
  width: 100%;
  display: inline-block;
  margin: calc(2*$margin) auto;
  line-height: 50px;
  text-align: center; }
.gacha__container{
  text-align: center; }
.gacha__container video{
  display: block;
  width: 100vw;
  height: calc(100vw * 0.563);
  margin: calc(50vh - 28vw + 26px) 0 0 0; }
.gacha__button, .gacha__button10{
  @mixin btn 300px, 60px, $white, $main_color;
  position: absolute;
  bottom: 120px;
  left: calc(50% - 150px);
  border: 3px solid $white !important; }
.gacha__button10{
  bottom: 30px; }
.gacha__point__container{
  @mixin container 400px, auto;
  margin: calc(50vh - 34*$margin) auto;
  border-radius: $default_border_radius;
  padding: calc(4*$padding);
  z-index: 93; }
.gacha__point__text__container, .gacha__point__title__container{
  @mixin container 100%, auto, $black;
  margin: 0 auto calc(4*$margin);
  padding: calc(2*$padding);
  color: $white;
  border-radius: $default_border_radius; }
.gacha__point__title__text{
  font-weight: $default_weight;
  color: $white; }
.gacha__point__points__container{
  @mixin container 100%, auto, $light_gray;
  margin: calc(6*$margin) auto calc(4*$margin);
  padding: calc(2*$margin);
  border-radius: $default_border_radius; }
.gacha__point__text__container{
  @mixin container auto, auto;
  margin: calc(2*$margin) auto; }
.gacha__user__point__text__container, .gacha__consume__point__text__container, .gacha__description__container{
  @mixin container 320px, auto, $light_gray;
  @mixin flexbox row, space-between, center;
  margin: calc(2*$margin) auto; }
.gacha__description__container{
  @mixin flexbox column, flex-start, center;
  background: $white !important; }
.gacha__user__point__text__container span, .gacha__consume__point__text__container span, .gacha__description__container span{
  display: inline-block;
  color: $black; }
.gacha__description__container span{
  background: $white !important; }
#gacha_user_point_text, #gacha_consume_point_text{
  font-weight: $default_weight;
  color: $accent_color; }
.gacha__result__container{
  @mixin container 80%, auto;
  margin: calc(12*$margin) auto;
  border-radius: $default_border_radius;
  padding: calc(4*$padding);
  z-index: 95; }
.gacha__result__text__container{
  @mixin container 90%, auto, $black;
  margin: calc(4*$margin) auto;
  padding: calc(2*$padding);
  color: $white;
  border-radius: $default_border_radius; }
.gacha__result__text{
  display: block;
  width: 100%;
  color: $white !important;
  background-color: $black !important;
  text-align: center; }
.gacha__result__img__container{
  @mixin container 50%, auto;
  max-width: calc(100vh - 400px);
  margin: 0 auto;
  padding: calc(2*$padding);
  border: 1px solid $black;
  border-radius: $default_border_radius; }
.gacha__result__img__container img{
  width: 100%; }
.gacha__result__button{
  @mixin btn 50% !important, auto, $white, $main_color;
  max-width: calc(100vh - 400px) !important; }
.fade__container {
  background-color: transparent;
  transition: opacity 0.5s;
  opacity: 0; }
.fade__container.is-show {
  background-color: rgba(0,0,0,.3);
  transition-delay: 0s;
  opacity: 1; }
.common__form, .common__item__list, .common__page{
  margin: 0 auto !important; }
.common__form{
  width: 60%; }
.common__item__list{
  width: 80%; }
.common__page{
  width: 100%; }
.common__form__item{
  padding: 8% 0;
  border: none !important; }
.common__form__item .label_wrapper, .common__form__item .input_container{
  width: 100%;
  float: none; }
.common__form__item .input_container input{
  width: 100%;
  border-radius: $default_border_radius; }
.common__form__item__file{
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important; }
ul.common__form__item__checkbox{
  list-style: none !important;
  padding: 0 !important; }
ul.common__form__item__checkbox li{
  @mixin flexbox row !important, flex-start !important, center !important; }
ul.common__form__item__checkbox li input{
  width: 30px !important;
  height: 30px !important; }
.common__form__image{
  width: 200px;
  z-index: 10; }
.common__form__image.rect__image{
  width: 356px; }
.common__form__image__container{
  @mixin container calc(200px + 2em), calc(200px + 2em);
  position: relative;
  box-shadow: 0 1px 2px 0 $box_shadow_color;
  margin: 0;
  padding: 1em 1em;
  border-radius: .28571429rem;
  border: 1px solid $box_shadow_color;
  cursor: pointer; }
.common__form__image__container.rect__image{
  width: calc(356px + 2em); }
.common__form__image__container .img_input_container{
  position: absolute;
  width: calc(200px + 2em);
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0; }
.login__contents {
  @mixin container 100vw, 780px;
  position: absolute;
  top: 0;
  left: 0; }
.login__contents__overlay{
  @mixin container 100vw, 780px;
  position: absolute;
  z-index: 90; }
.login__contents__container{
  width: 360px !important;
  margin-top: calc(10*$margin); }
.login__link__text, .login__link__text:link, .login__link__text:visited{
  color: $main_color !important; }
.login__form__text{
  @mixin txt $default_size !important, $default_weight !important, $default_line_height !important; }
.register__dropdown{
  color: #C9C9C9 !important;
  padding-left: calc(9*$padding) !important;
  background: transparent !important; }
.ui.stacked.segment .inline.fields{
  margin-left: $margin; }
.policy__section__header{
  font-weight: $bold_weight; }
.policy__section__contents{
  margin-left: calc(6*$margin); }
.policy__section__sub__contents{
  margin-left: calc(4*$margin); }
.policy__section__border__contents{
  width: 90%;
  border: 1px solid $black;
  padding: calc(2*$padding);
  margin-top: calc(2*$margin); }
#faq #contents-container{
  padding: calc(3*$padding);
  margin: calc(2*$margin) 0; }
#tradelaw #contents-container{
  width: 90%;
  margin: 20px auto; }
#top_video_container{
  @mixin container 100%, 56vw, #000;
  position: relative; }
#top_video_container video{
  @mixin container 100%, 100%;
  position: absolute;
  top: -70px; }
#ytplayer{
  position: absolute;
  top: -70px;
  left: 0;
  right: 0; }
#ytplayer_cover{
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 99; }
#ytplayer_bottom{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70px;
  background: #000;
  z-index: 90; }
#top_message_container{
  @mixin container 100%, auto, $dark_blue;
  z-index: 20; }
#top_message_text{
  @mixin container 100%, auto, $dark_blue;
  @mixin txt 1.7rem, $bold_weight !important, 1em, $white, $dark_blue, center;
  margin: 0;
  padding: calc(10*$padding) 0 0; }
#top_button_container{
  @mixin container 100%, auto, $dark_blue;
  @mixin flexbox row, center, center;
  padding: calc(10*$padding) 0; }
#top_link_download{
  font-weight: $bold_weight !important;
  background-color: #2492E5 !important; }
#top_link_download:hover{
  background-color: #28A2FF !important; }
.common-detail-info {
  float: left;
  margin: 0 calc(2*$margin);
  padding: 0 0 0 calc(4*$padding);
  background-size: 16px 16px;
  background-position: left center; }
.common-button-container {
  @mixin container 100%, auto;
  @mixin flexbox row, center, center;
  position: relative;
  margin-top: calc(2*$margin); }
.common-button, .common-small-button{
  @mixin btn calc(50% - 20px), 38px, $white, $main_color;
  position: relative;
  max-width: 330px;
  padding: $padding calc(2*$padding) $padding calc(2*$padding);
  margin: calc(2*$margin) !important;
  text-align: center; }
.common-button a, .common-button span, .common-small-button a, .common-small-button span {
  @mixin container 100%, 38px, transparent;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 38px;
  color: $white; }
.mypage__button a {
  @mixin container 100%, auto, transparent;
  color: $white; }
.mypage__list__item.description {
  padding: $padding 0 0; }
.common-button:hover, .common-small-button:hover{
  opacity: 0.5; }
.common-small-button {
  max-width: 80px; }
.common-overlay, .common-modal{
  @mixin container 100vw, 100vh, rgba(0,0,0,0.3);
  z-index: 110;
  position: fixed;
  top: 0;
  left: 0; }
.common-overlay-content, .common-modal-container{
  z-index: 111;
  min-width: 250px;
  width: 60%;
  padding: calc(6*$padding);
  overflow: auto;
  text-align: center;
  background: $white;
  border-radius: $default_border_radius; }
.common-overlay-content{
  margin: calc(20*$margin) auto; }
.common-modal-container{
  margin: calc(40*$margin) auto; }
.common-modal-label{
  @mixin txt $large_size !important, $bold_weight !important, $large_line_height !important, $black, $white, center;
  z-index: 113;
  margin: calc(4*$margin) auto;
  overflow: hidden; }
.common-modal-content{
  @mixin txt $default_size !important, $default_weight !important, $default_line_height !important, $black, $white, center;
  z-index: 112;
  margin: calc(4*$margin) auto; }
.common-modal-button, .common-modal-button-ok, .common-modal-button-ng{
  @mixin btn 120px, 50px, $black, $white;
  @mixin txt $default_size !important, $default_weight !important, $default_line_height !important, $black, $white, center;
  z-index: 113;
  margin: calc(4*$margin) auto calc(2*$margin);
  padding: $padding;
  border-radius: $default_border_radius; }
.common-modal-button-ok, .common-modal-button-ng{
  float: left;
  margin: calc(2*$margin) calc(3*$margin); }
.common-modal-button-container{
  @mixin container 300px, auto;
  margin: calc(2*$margin) auto; }
.common__space{
  width: 100%;
  height: calc(10*$margin); }
.common__section__hr{
  border-style: inset;
  border-width: 1px;
  margin: 0; }
.common__border__bottom{
  border-bottom: 1px solid $light_gray;
  margin: 0 0 $margin 0; }
.common__border__dashed{
  border-bottom: 1px dashed $light_gray; }
.common__large__font, .common__large__bold__font, .form-cancel-button, .form-submit-button, .common-button, .common-small-button, .common-text, .text-block{
  @mixin tsize $large_size !important, $default_weight, $large_line_height !important; }
.common__bold__font, .common__large__bold__font, .header__menu__item, .text-block{
  font-weight: $bold_weight !important; }
.common__small__font, .common-overlay-content{
  @mixin tsize $small_size !important, $default_weight, $small_line_height !important; }
.common__link__notice{
  color: $accent_color !important;
  text-decoration: underline; }
.common__space-between{
  width: 100%;
  @mixin flexbox row, space-between, center; }
.common__align-left{
  text-align: left;
  margin-left: calc(2*$margin); }
.common-input {
  outline: 0;
  color: inherit;
  width: 210px;
  height: 50px;
  vertical-align: middle;
  padding: 0 $padding 0 $padding;
  border: solid 1px $gray;
  float: left; }
.common-text {
  outline: 0;
  color: inherit;
  width: 210px;
  height: 60px;
  vertical-align: middle;
  padding: calc(2*$padding) calc(4*$padding);
  margin: calc(2*$margin) auto;
  text-align: center; }
.text-block {
  padding: calc(10*$padding);
  text-align: center;
  color: $white;
  text-shadow: $txt_shadow_color 3px 0px 0px, $txt_shadow_color -3px 0px 0px, $txt_shadow_color 0px -3px 0px, $txt_shadow_color 0px 3px 0px, $txt_shadow_color 3px 3px 0px, $txt_shadow_color -3px 3px 0px, $txt_shadow_color 3px -3px 0px, $txt_shadow_color -3px -3px 0px, $txt_shadow_color 1px 3px 0px, $txt_shadow_color -1px 3px 0px, $txt_shadow_color 1px -3px 0px, $txt_shadow_color -1px -3px 0px, $txt_shadow_color 3px 1px 0px, $txt_shadow_color -3px 1px 0px, $txt_shadow_color 3px -1px 0px, $txt_shadow_color -3px -1px 0px, $txt_shadow_color 1px 1px 0px, $txt_shadow_color -1px 1px 0px, $txt_shadow_color 1px -1px 0px, $txt_shadow_color -1px -1px 0px, $txt_shadow_color 2px 2px 10px; }
.text-black, .text-black:visited {
  color: $black !important; }
.underline{
  text-decoration: underline; }
.hidden, common__form__item__hidden{
  display: none !important; }
.float-right{
  float: right; }
.float-left{
  float: left; }
.clear{
  clear: both; }
.inline-block{
  display: inline-block; }
.no-margin-right{
  margin-right: 0 !important; }
.no-padding{
  padding: 0 !important; }
.header__menu .pc {
  display: block; }
.header__menu .md {
  display: none; }
.text-center{
  text-align: center !important; }
.border-bottom-none{
  border-bottom: none; }
.cursor_auto{
  cursor: auto; }
.grayscale {
  filter: grayscale(100%); }
.hover_opacity:hover{
  opacity: 0.5; }
.hover_bright:hover{
  filter: brightness(110%); }
.hover_dark:hover{
  filter: brightness(90%); }
.pagination {
  @mixin flexbox row, center, center; }
.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: $main_color;
  background-color: $white;
  border: 1px solid $light_gray;
  cursor: pointer; }
.page-item {
  @mixin flexbox row, center, center;
  padding-left: 0;
  list-style: none; }
.page-item.active .page-link{
  color: $white;
  background-color: $main_color; }
.page-item.disabled, .page-item.next, .page-item.prev{
  display: none !important; }
.info-pane {
  margin: 0.5em 0; }
.download_app_base {
  @mixin container 150px, 245px, $white;
  position: relative;
  margin: calc($margin*10) auto;
  border: 1px $black solid;
  text-align: left;
  .download_icon_container {
    position: relative;
    width: 148px;
    height: 100px;
    background: #00bdff;
    .download_icon_oculus {
      width: 50px;
      margin: 35px 0 15px 10px; }
    .download_icon_vive {
      width: 50px;
      margin: 25px 0 10px 10px; }}
  .windows_title p {
    text-align: left;
    margin: 17px 0 10px;
    span {
      padding-left: 12px;
      color: $black;
      font-size: 1.3em;
      font-weight: $bold_weight;
      letter-spacing: 0em; }}
  p span {
    padding-left: 12px;
    color: $black;
    font-size: 1.0em;
    letter-spacing: 0em; }
  .download_btn {
    @mixin btn 90%, auto, $white, #ff008f;
    margin-top: 12px;
    padding: 4px;
    border: solid 1px #ff008f;
    border-radius: 70px;
    margin:12px 5% 20px;
    &:focus{
      outline: 0;
      opacity: 0.5; }}
  * {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    box-sizing: border-box; }
  *:before,
  *:after {
    box-sizing: border-box; }}
.staff{
  .pane{
    margin: calc($margin*3) 0; }
  .prof{
    margin: calc($margin*5) 0; }
  span, a, h3{
    @mixin txt $default_size, $bold_weight, $default_line_height, $black !important, $white !important, center;
    width: 100%;
    display: inline-block;
    padding: calc($padding*2) 0 calc($padding*7); }
  .charactor{
    color: $white !important;
    background: $black !important; }
  span.name {
    color: $white !important; }
  span.post {
    color: $light_green !important; }}
/* responsive */
@media only screen and (max-width: 930px) {
  .live__list{ width: 100%; } }
@media only screen and (max-width: 800px) {
  .common__item__list{ width: 90%; } }
@media only screen and (max-width: 760px) {
  .common__form{ width: 70%; }
  .common__item__list{ width: 100%; }
  #purchase__form{ width: 100%; }
  #tradelaw #contents-container{ width: 100%; } }
@media only screen and (max-width: 600px) {
  .header__menu__item {
    @mixin txt $default_size !important, $default_weight !important, $default_line_height !important; }
  .common__form{ width: 80%; } }
@media only screen and (max-width: 480px) {
  .header__menu__item {
    @mixin txt $small_size !important, $default_weight, $small_line_height !important; }
  .common__form{ width: 90%; } }
@media only screen and (max-width: 380px) {
  header{
    width: 100%;
    padding: 0;
  }
  .header__menu__item { font-size: 10px; }
  .common__form{ width: 100%; } }
